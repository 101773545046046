// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".l3Cr04wM6kSfO9fSCX2Y{display:flex;width:290px;height:56px;overflow:hidden;padding:10px;border-radius:4px;border:1px solid #d4d5d7}.t_xS6kUb_5Jp2W9v9LIE{display:flex;flex-flow:column}.RG3NIRQMObt_IRUmFwyD{width:40px;height:40px;margin-left:auto;margin-top:auto;margin-bottom:auto}.VsN9mPGjGupYRZo_imYJ{margin-right:auto}.SRYhu2jjEdIWPwuIx33t{margin-bottom:4px}", "",{"version":3,"sources":["webpack://./src/templates/teacherDashboard/LmsInformationalCard/lmsInformationalCard.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,WAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CACA,iBAAA,CACA,wBAAA,CAGF,sBACE,YAAA,CACA,gBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,iBAAA","sourcesContent":["@import 'color';\n\n.card {\n  display: flex;\n  width: 290px;\n  height: 56px;\n  overflow: hidden;\n  padding: 10px;\n  border-radius: 4px;\n  border: 1px solid #D4D5D7;\n}\n\n.cardText {\n  display: flex;\n  flex-flow: column;\n}\n\n.logo {\n  width: 40px;\n  height: 40px;\n  margin-left: auto;\n  margin-top: auto;\n  margin-bottom: auto;\n}\n\n.lmsInstructionsLink {\n  margin-right: auto;\n}\n\n.title {\n  margin-bottom: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "l3Cr04wM6kSfO9fSCX2Y",
	"cardText": "t_xS6kUb_5Jp2W9v9LIE",
	"logo": "RG3NIRQMObt_IRUmFwyD",
	"lmsInstructionsLink": "VsN9mPGjGupYRZo_imYJ",
	"title": "SRYhu2jjEdIWPwuIx33t"
};
export default ___CSS_LOADER_EXPORT___;
