// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KXmxTRHUYjJ6sgjwbnUy{color:inherit;margin:0;padding:0;border:none;border-radius:0;background-color:rgba(0,0,0,0)}.KXmxTRHUYjJ6sgjwbnUy:hover{box-shadow:none}.KXmxTRHUYjJ6sgjwbnUy:active{border:none !important}", "",{"version":3,"sources":["webpack://./src/templates/collapser-icon.module.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAEA,sBCMI,aDL4B,CCgB9B,QAAA,CACA,SAAA,CACA,WAAA,CACA,eAAA,CACA,8BAAA,CAEA,4BACE,eAAA,CAGF,6BACE,sBAAA","sourcesContent":["@import \"../mixins.scss\";\n\n.collapserIcon {\n  @include remove-button-styles(inherit);\n}\n","// Removes (most) default button styling. Useful for retaining a clickable component's\n// original presentation when converting it to a <button>\n@mixin remove-button-styles(\n  $color: false,\n  $font-size: false,\n  $line-height: false\n) {\n  @if $color {\n    color: $color;\n  }\n\n  @if $font-size {\n    font-size: $font-size;\n  }\n\n  @if $line-height {\n    line-height: $line-height;\n  }\n\n  margin: 0;\n  padding: 0;\n  border: none;\n  border-radius: 0;\n  background-color: transparent;\n\n  &:hover {\n    box-shadow: none;\n  }\n\n  &:active {\n    border: none !important;\n  }\n}\n\n@mixin icon-button($background, $selected, $active) {\n  i {\n    color: var(--text-neutral-tertiary);\n  }\n\n  &.selected i, &:hover i {\n    color: $selected;\n  }\n\n  &:hover {\n    background-color: $background;\n  }\n\n  &:active i {\n    color: $active;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapserIcon": "KXmxTRHUYjJ6sgjwbnUy"
};
export default ___CSS_LOADER_EXPORT___;
